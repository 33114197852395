@import url(../../helpers/styles.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroud);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.option-font {
  font-family: FontAwesome, sans-serif;
}


.delete-btn {
  background: none;
  color: black;
  border: none;
}

.bg_exactian_gradient {
  background: var(--nav-backgroud);

}

.alert-primary {
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.alert-primary hr {
  border-top-color: var(--main-color);
}

a {
  text-decoration: none;
  color: var(--contrast-color-secondary);
}

a:hover {
  color: var(--contrast-color-secondary);
}

.icon-style {
  color: var(--main-color);
}

.alert-primary .alert-link {
  color: var(--main-color);
}

.badge-primary {
  color: var(--contrast-color);
  background-color: var(--main-color);
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: var(--contrast-color);
  background-color: var(--main-color);
}

.bg-primary {
  background-color: var(--main-color) !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: var(--main-color) !important;
}

.border-primary {
  border-color: var(--main-color) !important;
}

.btn-primary {
  color: var(--contrast-color);
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.btn-primary:hover {
  color: var(--contrast-color);
  background-color: var(--main-color-hover) !important;
  border-color: var(--main-color-hover) !important;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);
}

.btn-outline-primary {
  color: var(--main-color);
  background-color: transparent;
  border-color: var(--main-color);
}

.btn-outline-primary:hover {
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--main-color);
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

/* #e9ecef */
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--dropdown-item-color);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--dropdown-item-hover);
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: var(--dropdown-item-active);
}

.dropdown-menu {
  background-color: var(--contrast-color);
  padding: 0;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--main-color);
  background-color: var(--dropdown-item-hover);
}

.dropdown-item {
  color: var(--dropdown-menu-item-active);
  padding: .80rem 1rem;
}

.dropdown-divider {
  margin: 0;
}

.list-group-item-primary {
  color: var(--main-color);
  background-color: var(--main-color);
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: var(--main-color);
  background-color: var(--main-color);
}

.list-group-item-primary.list-group-item-action.active {
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: var(--main-color);
}

.table-hover .table-primary:hover {
  background-color: var(--main-color);
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: var(--main-color);
}

.text-primary {
  color: var(--main-color) !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: var(--main-color) !important;
}

.form-select:focus {
  border-color: var(--main-color);
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);
  outline: 0 none;
}

.form-control:focus {
  border-color: var(--main-color);
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);
  outline: 0 none;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--main-color-hover);

}

.list-group-item.list-group-item.active {
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.page-link {
  color: var(--main-color);
}

.page-link:hover {
  color: var(--main-color);
}

.page-link:focus {
  color: var(--main-color);
  background-color: var(--contrast-color);
  box-shadow: 0 0 0 .25rem var(--main-color-hover);
}

.page-item.active .page-link {
  z-index: 3;
  color: var(--contrast-color);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.table-inductions-container {
  overflow-y: scroll;
  max-height: 247px;
}

.table-inductions-container thead {
  position: sticky;
  top: 0;
}

.react-pdf__Page__svg {
  width: 100% !important;
  height: 100% !important;
}

svg {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf__message--loading {
  display: flex;
  justify-content: center;
  margin: 500px 0px 500px 0px;
}

.react-pdf__Page__annotations {
  display: none !important;
}

.exam-button-position-pdf {
  position: relative;
  bottom: 50px;
  justify-content: end;
  float: right;
}

.exam-button-position-video {
  justify-content: end;
  margin-top: 20px;
}

/* #reactgooglegraph-1 {
  position: relative;
  left: 200px;
} */

@media (max-width: 710px) {
  .custom-exam-flex {
    flex-wrap: wrap;
  }
}

@media (max-width: 995px) {
  .c-wrap {
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .exam-button-position-pdf {
    margin-top: 30px;
    position: initial;
    justify-content: center;
    float: none;
  }

  .exam-button-position-video {
    justify-content: center;
    margin-top: 20px;
  }
}