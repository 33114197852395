:root {
    --main-color: #1c396d;
    --main-color-hover: #274e8e;
    --contrast-color: #ffffff;
    --dropdown-item-hover: #e9ecef;
    --dropdown-item-color: rgba(255, 255, 255, 0.5);
    --dropdown-item-active: white;
    --dropdown-menu-item-active: black;
    --contrast-color-secondary: #000000;
    --backgroud: #ececec;
    --nav-backgroud: #1c396d;
}